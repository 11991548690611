<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="isSystemAdmin() || creatable">
              <b-col cols="8">
                <h3>
                  Các quyền cho vai trò <strong><u>{{ roleName }}</u></strong>
                </h3>
              </b-col>
              <b-col cols="4">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.assignPermissionResourceModal
                    variant="success"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm quyền
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row :class="{'mt-2': isSystemAdmin() || creatable}">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="permissionResources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                      <b-button
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        @click="onDeleteResource(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ permissionResources.length }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <AssignPermissionResource
      :role-id="selectedId"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssignPermissionResource from '@/views/authorization/role-permission-resource/AssignPermissionResource.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'RolePermissionResource',
  directives: {
    Ripple,
  },
  components: {
    AssignPermissionResource,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        resourceName: null,
        resourceCode: null,
        permissionCode: null,
        sort: null,
      },
      currentResource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      selectedId: +this.$route.params.id,
      roleName: '',
    }
  },
  computed: {
    ...mapGetters({
      permissionResources: 'role/permissionResources',
      totalRows: 'role/permissionResourceTotalRows',
      permissions: 'role/permissionOptions',
      resources: 'role/resourcesOptions',
    }),
    columns() {
      let data = [
        {
          label: 'Mã quyền',
          field: 'permissionCode',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Quyền',
          field: 'permissionName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.permissions.map(permission => ({ value: permission.code, text: permission.name })),
            placeholder: 'Chọn quyền',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã đối tượng',
          field: 'resourceCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã đối tượng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Đối tượng',
          field: 'resourceName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập đối tượng',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ]
      if (isSystemAdmin() || this.deletable) {
        data = [...data, {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        }]
      }
      return data
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ROLE_PERMISSION_RESOURCE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ROLE_PERMISSION_RESOURCE)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.begin(),
        this.getPermissionResources({
          id: this.selectedId,
          params: this.filter,
        }),
      ])
      if (this.permissionResources.length > 0) {
        this.roleName = this.permissionResources[0].roleName
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      begin: 'role/permissionResourcesBegin',
      getPermissionResources: 'role/getPermissionResources',
      deleteRolePermissionResource: 'role/deleteRolePermissionResource',
    }),
    onDeleteResource(permissionResource) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteRolePermissionResource({
              id: this.selectedId,
              param: {
                permissionResource: {
                  permissionCode: permissionResource.permissionCode,
                  resourceCode: permissionResource.resourceCode,
                },
              },
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getPermissionResources({
                    id: this.selectedId,
                    params: this.filter,
                  })
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getPermissionResourcesFromStore()
    },
    async getPermissionResourcesFromStore() {
      this.isLoading = true
      try {
        await this.getPermissionResources({
          id: this.selectedId,
          params: this.filter,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getPermissionResourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        switch (field) {
          case 'permissionCode':
            this.updateParams({ sort: `1_${type}` })
            break
          case 'permissionName':
            this.updateParams({ sort: `2_${type}` })
            break
          case 'resourceCode':
            this.updateParams({ sort: `3_${type}` })
            break
          default:
            this.updateParams({ sort: `4_${type}` })
            break
        }
        await this.getPermissionResourcesFromStore()
      }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'permissionName')) this.updateParams({ permissionCode: columnFilters.permissionName })
      if (Object.hasOwn(columnFilters, 'resourceName')) this.updateParams({ resourceName: columnFilters.resourceName })
      if (Object.hasOwn(columnFilters, 'resourceCode')) this.updateParams({ resourceCode: columnFilters.resourceCode })
      await this.getPermissionResourcesFromStore()
    },
    async onSucceed() {
      await this.getPermissionResourcesFromStore()
    },
  },
}
</script>
